import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Petroleum Exploration Society of Great Britain, in collaboration with the
Geological Society of  Houston, hosted its 14th conference on African
Exploration and Production in London, September 3  and 4. About 600 delegates
attended, mostly from across the spectrum of commercial interests in  petroleum
exploration in Africa. There were two days of talks, an extensive poster display
and a  large exhibition area for commercial booths in the former Royal
Agricultural Hall in Islington. `}</p>
    <p>{`Colin Reeves gave a presentation at the end of the first day under the title
"Insight into the east coast of Africa from a new tectonic model of the early
Indian Ocean". The talk recounted recent  work on interpreting ocean floor data
to establish the earliest relative movements of East and West  Gondwana in terms
of Euler rotation poles, prior to the transition to a well-established
Antarctica  versus Africa pathway in Kimmeridgian times, about 153 Ma. Colin was
delighted to receive the  'Best Oral Paper' award for this at the closing of the
meeting. He extends thanks to his co-authors  Jon Teasdale and Stefane
Mahanjane. Photo coutesy of PESGB. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      